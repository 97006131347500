import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { LoaderFunctionArgs } from "react-router-dom";

export const ordersLoader = async (): Promise<unknown | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let orders: unknown | null = null;

  try {
    const req = await fetch(`${envProps.base_url}/api/v1/admin/order`, {
        headers,
      }),
      res = await req.json();
    console.log(res);

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, Brand not found.");
    }
  } catch (error) {
    console.error("Error fetching orders:", error);
    // throw new Error("Error fetching brand");
  }

  return orders;
};

export const orderDetailsLoader = async ({ params }: LoaderFunctionArgs) => {
  const orderId = params.id;
  if (!orderId) return null;

  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  try {
    const req = await fetch(
      `${envProps.base_url}/api/v1/admin/get-order/${orderId}`,
      {
        headers,
      }
    );
    const res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, Order not found.");
    }
  } catch (error) {
    console.error("Error fetching order:", error);
    throw error;
  }
};
