import { takeLatest, put, all, call } from "typed-redux-saga/macro";
import { LOGISTICS_ACTION_TYPES } from "./logistics.types";
import {
  fetchPartnersSuccess,
  fetchPartnersFailed,
  fetchPartnerSuccess,
  fetchPartnerFailed,
  addPartnerSuccess,
  addPartnerFailed,
  updatePartnerSuccess,
  updatePartnerFailed,
  deletePartnerSuccess,
  deletePartnerFailed,
  FetchPartnerStart,
  AddPartnerStart,
  UpdatePartnerStart,
  DeletePartnerStart,
} from "./logistics.action";
import { apiRequest } from "lib/api/apiClient";
import { alertMessage } from "components/toolkit/initial-state.component";
import { logisticsActions } from "./logistics.slice";

export function* fetchPartners() {
  try {
    const partners = yield* call(apiRequest, "/api/v1/admin/delivery-services");
    yield* put(fetchPartnersSuccess(partners));
  } catch (error) {
    yield* put(fetchPartnersFailed(error as string));
    alertMessage("error", "Failed to fetch partners");
  }
}

export function* fetchPartner({ payload }: FetchPartnerStart) {
  try {
    yield* put(logisticsActions.setLoading(true));
    yield* put(logisticsActions.setPartner(null));
    const partner = yield* call(
      apiRequest,
      `/api/v1/admin/delivery-services/${payload}`
    );
    if (partner) {
      yield* put(fetchPartnerSuccess(partner));
    }
  } catch (error) {
    yield* put(fetchPartnerFailed(error as string));
    alertMessage("error", "Failed to fetch partner details");
  } finally {
    yield* put(logisticsActions.setLoading(false));
  }
}

export function* addPartner({ payload }: AddPartnerStart) {
  try {
    const partners = yield* call(
      apiRequest,
      "/api/v1/admin/delivery-services",
      {
        method: "POST",
        body: payload,
      }
    );
    yield* put(addPartnerSuccess(partners));
    alertMessage("success", "Partner added successfully");
  } catch (error) {
    yield* put(addPartnerFailed(error as string));
    alertMessage("error", "Failed to add partner");
  }
}

export function* updatePartner({ payload }: UpdatePartnerStart) {
  try {
    const { partnerId, partnerData } = payload;
    const partners = yield* call(
      apiRequest,
      `/api/v1/admin/delivery-services/${partnerId}`,
      {
        method: "PUT",
        body: partnerData,
      }
    );
    yield* put(updatePartnerSuccess(partners));
    alertMessage("success", "Partner updated successfully");
  } catch (error) {
    yield* put(updatePartnerFailed(error as string));
    alertMessage("error", "Failed to update partner");
  }
}

export function* deletePartner({ payload }: DeletePartnerStart) {
  try {
    const partners = yield* call(
      apiRequest,
      `/api/v1/admin/delivery-services/${payload}`,
      {
        method: "DELETE",
      }
    );
    yield* put(deletePartnerSuccess(partners));
    alertMessage("success", "Partner deleted successfully");
  } catch (error) {
    yield* put(deletePartnerFailed(error as string));
    alertMessage("error", "Failed to delete partner");
  }
}

export function* onFetchPartnersStart() {
  yield* takeLatest(LOGISTICS_ACTION_TYPES.FETCH_PARTNERS_START, fetchPartners);
}

export function* onFetchPartnerStart() {
  yield* takeLatest(LOGISTICS_ACTION_TYPES.FETCH_PARTNER_START, fetchPartner);
}

export function* onAddPartnerStart() {
  yield* takeLatest(LOGISTICS_ACTION_TYPES.ADD_PARTNER_START, addPartner);
}

export function* onUpdatePartnerStart() {
  yield* takeLatest(LOGISTICS_ACTION_TYPES.UPDATE_PARTNER_START, updatePartner);
}

export function* onDeletePartnerStart() {
  yield* takeLatest(LOGISTICS_ACTION_TYPES.DELETE_PARTNER_START, deletePartner);
}

export function* logisticsSaga() {
  yield* all([
    call(onFetchPartnersStart),
    call(onFetchPartnerStart),
    call(onAddPartnerStart),
    call(onUpdatePartnerStart),
    call(onDeletePartnerStart),
  ]);
}
