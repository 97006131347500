import {
  Action,
  withMatcher,
  createAction,
  ActionWithPayload,
} from "utils/reducer/reducer.utils";
import { LOGISTICS_ACTION_TYPES } from "./logistics.types";
import { LogisticsPartner } from "utils/types/logistics";
import { logisticsActions } from "./logistics.slice";

// Slice actions
export const setPartners = (partners: LogisticsPartner[]) =>
  logisticsActions.setPartners(partners);

export const setPartner = (partner: LogisticsPartner | null) =>
  logisticsActions.setPartner(partner);

// Action types
export type FetchPartnersStart =
  Action<LOGISTICS_ACTION_TYPES.FETCH_PARTNERS_START>;

export type FetchPartnerStart = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.FETCH_PARTNER_START,
  number
>;

export type AddPartnerStart = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.ADD_PARTNER_START,
  Partial<LogisticsPartner>
>;

export type UpdatePartnerStart = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.UPDATE_PARTNER_START,
  { partnerId: number; partnerData: Partial<LogisticsPartner> }
>;

export type DeletePartnerStart = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.DELETE_PARTNER_START,
  number
>;

// Success and failure action types
export type FetchPartnersSuccess = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.FETCH_PARTNERS_SUCCESS,
  LogisticsPartner[]
>;

export type FetchPartnersFailed = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.FETCH_PARTNERS_FAILED,
  string
>;

export type FetchPartnerSuccess = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.FETCH_PARTNER_SUCCESS,
  LogisticsPartner
>;

export type FetchPartnerFailed = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.FETCH_PARTNER_FAILED,
  string
>;

export type AddPartnerSuccess = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.ADD_PARTNER_SUCCESS,
  LogisticsPartner[]
>;

export type AddPartnerFailed = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.ADD_PARTNER_FAILED,
  string
>;

export type UpdatePartnerSuccess = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.UPDATE_PARTNER_SUCCESS,
  LogisticsPartner[]
>;

export type UpdatePartnerFailed = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.UPDATE_PARTNER_FAILED,
  string
>;

export type DeletePartnerSuccess = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.DELETE_PARTNER_SUCCESS,
  LogisticsPartner[]
>;

export type DeletePartnerFailed = ActionWithPayload<
  LOGISTICS_ACTION_TYPES.DELETE_PARTNER_FAILED,
  string
>;

// Action creators with matchers
export const fetchPartnersStart = withMatcher(
  (): FetchPartnersStart =>
    createAction(LOGISTICS_ACTION_TYPES.FETCH_PARTNERS_START)
);

export const fetchPartnerStart = withMatcher(
  (partnerId: number): FetchPartnerStart =>
    createAction(LOGISTICS_ACTION_TYPES.FETCH_PARTNER_START, partnerId)
);

export const addPartnerStart = withMatcher(
  (partnerData: Partial<LogisticsPartner>): AddPartnerStart =>
    createAction(LOGISTICS_ACTION_TYPES.ADD_PARTNER_START, partnerData)
);

export const updatePartnerStart = withMatcher(
  (
    partnerId: number,
    partnerData: Partial<LogisticsPartner>
  ): UpdatePartnerStart =>
    createAction(LOGISTICS_ACTION_TYPES.UPDATE_PARTNER_START, {
      partnerId,
      partnerData,
    })
);

export const deletePartnerStart = withMatcher(
  (partnerId: number): DeletePartnerStart =>
    createAction(LOGISTICS_ACTION_TYPES.DELETE_PARTNER_START, partnerId)
);

// Success and failure actions
export const fetchPartnersSuccess = (partners: LogisticsPartner[]) =>
  logisticsActions.setPartners(partners);

export const fetchPartnersFailed = withMatcher(
  (error: string): FetchPartnersFailed =>
    createAction(LOGISTICS_ACTION_TYPES.FETCH_PARTNERS_FAILED, error)
);

export const fetchPartnerSuccess = (partner: LogisticsPartner) =>
  logisticsActions.setPartner(partner);

export const fetchPartnerFailed = withMatcher(
  (error: string): FetchPartnerFailed =>
    createAction(LOGISTICS_ACTION_TYPES.FETCH_PARTNER_FAILED, error)
);

export const addPartnerSuccess = withMatcher(
  (partners: LogisticsPartner[]): AddPartnerSuccess =>
    createAction(LOGISTICS_ACTION_TYPES.ADD_PARTNER_SUCCESS, partners)
);

export const addPartnerFailed = withMatcher(
  (error: string): AddPartnerFailed =>
    createAction(LOGISTICS_ACTION_TYPES.ADD_PARTNER_FAILED, error)
);

export const updatePartnerSuccess = withMatcher(
  (partners: LogisticsPartner[]): UpdatePartnerSuccess =>
    createAction(LOGISTICS_ACTION_TYPES.UPDATE_PARTNER_SUCCESS, partners)
);

export const updatePartnerFailed = withMatcher(
  (error: string): UpdatePartnerFailed =>
    createAction(LOGISTICS_ACTION_TYPES.UPDATE_PARTNER_FAILED, error)
);

export const deletePartnerSuccess = withMatcher(
  (partners: LogisticsPartner[]): DeletePartnerSuccess =>
    createAction(LOGISTICS_ACTION_TYPES.DELETE_PARTNER_SUCCESS, partners)
);

export const deletePartnerFailed = withMatcher(
  (error: string): DeletePartnerFailed =>
    createAction(LOGISTICS_ACTION_TYPES.DELETE_PARTNER_FAILED, error)
);
