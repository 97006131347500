export enum SETTINGS_ACTION_TYPES {
  FETCH_FAQS_START = "settings/FETCH_FAQS_START",
  FETCH_FAQS_SUCCESS = "settings/FETCH_FAQS_SUCCESS",
  FETCH_FAQS_FAILED = "settings/FETCH_FAQS_FAILED",

  ADD_FAQ_START = "settings/ADD_FAQ_START",
  ADD_FAQ_SUCCESS = "settings/ADD_FAQ_SUCCESS",
  ADD_FAQ_FAILED = "settings/ADD_FAQ_FAILED",

  UPDATE_FAQ_START = "settings/UPDATE_FAQ_START",
  UPDATE_FAQ_SUCCESS = "settings/UPDATE_FAQ_SUCCESS",
  UPDATE_FAQ_FAILED = "settings/UPDATE_FAQ_FAILED",

  DELETE_FAQ_START = "settings/DELETE_FAQ_START",
  DELETE_FAQ_SUCCESS = "settings/DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAILED = "settings/DELETE_FAQ_FAILED",

  SET_INTRO_MODE = "settings/SET_INTRO_MODE",
  SET_COUPON_NEWSLETTER = "settings/SET_COUPON_NEWSLETTER",
  SET_METADATA = "settings/SET_METADATA",

  FETCH_HELP_ARTICLES_START = "settings/FETCH_HELP_ARTICLES_START",
  FETCH_HELP_ARTICLES_SUCCESS = "settings/FETCH_HELP_ARTICLES_SUCCESS",
  FETCH_HELP_ARTICLES_FAILED = "settings/FETCH_HELP_ARTICLES_FAILED",

  FETCH_HELP_SECTIONS_START = "settings/FETCH_HELP_SECTIONS_START",
  FETCH_HELP_SECTIONS_SUCCESS = "settings/FETCH_HELP_SECTIONS_SUCCESS",
  FETCH_HELP_SECTIONS_FAILED = "settings/FETCH_HELP_SECTIONS_FAILED",
}
