import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LogisticsPartner, LogisticsState } from "utils/types/logistics";
import { LOGISTICS_ACTION_TYPES } from "./logistics.types";

const initialState: LogisticsState = {
  partners: null,
  partner: null,
  loading: false,
  error: null,
};

const logisticsSlice = createSlice({
  name: "logistics",
  initialState,
  reducers: {
    setPartners: (state, action: PayloadAction<LogisticsPartner[]>) => {
      state.partners = action.payload;
    },
    setPartner: (state, action: PayloadAction<LogisticsPartner | null>) => {
      state.partner = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    resetPartner: (state) => {
      state.partner = null;
    },
  },
});

export const logisticsActions = logisticsSlice.actions;
export default logisticsSlice;
