import { LIVE_MODE_KEY } from "./states";

export const getEnvProps = async () => {
  const liveMode = localStorage.getItem(LIVE_MODE_KEY);
  const base_url =
    liveMode === "true" || liveMode === null
      ? process.env.REACT_APP_BACKEND_URL
      : process.env.REACT_APP_TEST_BACKEND_URL ??
        "https://api.dev.cusorcart.com";

  const apiKey = process.env.REACT_APP_API_KEY;

  return {
    apiKey,
    base_url,
  };
};

export const getMetaData = async () => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const base_url = process.env.REACT_APP_BACKEND_URL;
  if (apiKey == null) return null;
  try {
    const req = await fetch(`${base_url}/api/v1/site-settings`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Api-Key": apiKey,
        },
      }),
      res = await req.json();

    if (res.success != null) {
      if (res.success) return await res.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
