import { combineReducers } from "redux";
import dataSlice from "./data/data.slice";
import userSlice from "./user/user.slice";
import categorySlice from "./category/category.slice";
import brandSlice from "./brand/brand.slice";
import staffSlice from "./staff/staff.slice";
import attributeSlice from "./attribute/attribute.slice";
import colorSlice from "./color/color.slice";
import productSlice from "./product/product.slice";
import usersSlice from "./users/users.slice";
import blogSlice from "./blog/blog.slice";
import { themeReducer } from "./theme/theme.reducer";
import couponSlice from "./coupon/coupon.slice";
import policySlice from "./policy/policy.slice";
import supportSlice from "./tickets/ticket.slice";
import activitySlice from "./activity/activity.slice";
import shippingSlice from "./shipping/shipping.slice";
import marketingSlice from "./marketing/marketing.slice";
import orderSlice from "./order/order.slice";
import settingsSlice from "./settings/settings.slice";
import logisticsSlice from "./logistics/logistics.slice";

export const rootReducer = combineReducers({
  data: dataSlice.reducer,
  user: userSlice.reducer,
  category: categorySlice.reducer,
  brand: brandSlice.reducer,
  staff: staffSlice.reducer,
  attribute: attributeSlice.reducer,
  color: colorSlice.reducer,
  products: productSlice.reducer,
  users: usersSlice.reducer,
  blog: blogSlice.reducer,
  coupons: couponSlice.reducer,
  policy: policySlice.reducer,
  support: supportSlice.reducer,
  activity: activitySlice.reducer,
  shipping: shippingSlice.reducer,
  marketing: marketingSlice.reducer,
  orders: orderSlice.reducer,
  settings: settingsSlice.reducer,
  logistics: logisticsSlice.reducer,
  theme: themeReducer,
});
