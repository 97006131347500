"use client";
import { selectCurrencies, selectCurrency } from "store/data/data.selector";
import { setCurrency } from "store/data/data.slice";
import { classNames } from "utils/helper/helper";
import { Currency } from "utils/types/app";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";

function CurrencySwitcher() {
  const currencies = useSelector(selectCurrencies),
    currency = useSelector(selectCurrency),
    dispatch = useDispatch();
  const handleChange = (currency: Currency) => {
    dispatch(setCurrency(currency));
  };
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button variant="bordered">
          <span className="capitalize">
            {currency ? `${currency.name} (${currency.code})` : "NGN"}
          </span>
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Dynamic Actions"
        items={currencies || undefined}
      >
        {(item) => (
          <DropdownItem
            key={item.id}
            color={"default"}
            className={classNames(
              "skiptranslate",
              currency && item.id === currency.id && "bg-secondary"
            )}
            onPress={() => handleChange(item)}
          >
            {`${item.name} (${item.code})`}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default CurrencySwitcher;
