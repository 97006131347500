import { createAction } from "utils/reducer/reducer.utils";
import { SETTINGS_ACTION_TYPES } from "./settings.types";
import { FAQ, HelpArticle, HelpSection, Metadata } from "utils/types/settings";

// FAQ Actions
export const fetchFaqsStart = () =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_FAQS_START);

export const fetchFaqsSuccess = (faqs: FAQ[]) =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_FAQS_SUCCESS, faqs);

export const fetchFaqsFailed = (error: Error) =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_FAQS_FAILED, error);

export const addFaqStart = (faq: Omit<FAQ, "id">) =>
  createAction(SETTINGS_ACTION_TYPES.ADD_FAQ_START, faq);

export const addFaqSuccess = (faq: FAQ) =>
  createAction(SETTINGS_ACTION_TYPES.ADD_FAQ_SUCCESS, faq);

export const addFaqFailed = (error: Error) =>
  createAction(SETTINGS_ACTION_TYPES.ADD_FAQ_FAILED, error);

export const updateFaqStart = (faq: FAQ) =>
  createAction(SETTINGS_ACTION_TYPES.UPDATE_FAQ_START, faq);

export const updateFaqSuccess = (faq: FAQ) =>
  createAction(SETTINGS_ACTION_TYPES.UPDATE_FAQ_SUCCESS, faq);

export const updateFaqFailed = (error: Error) =>
  createAction(SETTINGS_ACTION_TYPES.UPDATE_FAQ_FAILED, error);

export const deleteFaqStart = (id: number) =>
  createAction(SETTINGS_ACTION_TYPES.DELETE_FAQ_START, id);

export const deleteFaqSuccess = (id: number) =>
  createAction(SETTINGS_ACTION_TYPES.DELETE_FAQ_SUCCESS, id);

export const deleteFaqFailed = (error: Error) =>
  createAction(SETTINGS_ACTION_TYPES.DELETE_FAQ_FAILED, error);

// Settings Actions
export const setIntroMode = (enabled: boolean) =>
  createAction(SETTINGS_ACTION_TYPES.SET_INTRO_MODE, enabled);

export const setCouponNewsletter = (enabled: boolean) =>
  createAction(SETTINGS_ACTION_TYPES.SET_COUPON_NEWSLETTER, enabled);

export const setMetadata = (metadata: Metadata) =>
  createAction(SETTINGS_ACTION_TYPES.SET_METADATA, metadata);

// Help Articles Actions
export const fetchHelpArticlesStart = () =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_HELP_ARTICLES_START);

export const fetchHelpArticlesSuccess = (articles: HelpArticle[]) =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_HELP_ARTICLES_SUCCESS, articles);

export const fetchHelpArticlesFailed = (error: Error) =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_HELP_ARTICLES_FAILED, error);

// Help Sections Actions
export const fetchHelpSectionsStart = () =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_HELP_SECTIONS_START);

export const fetchHelpSectionsSuccess = (sections: HelpSection[]) =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_HELP_SECTIONS_SUCCESS, sections);

export const fetchHelpSectionsFailed = (error: Error) =>
  createAction(SETTINGS_ACTION_TYPES.FETCH_HELP_SECTIONS_FAILED, error);
