export enum LOGISTICS_ACTION_TYPES {
  FETCH_PARTNERS_START = "logistics/FETCH_PARTNERS_START",
  FETCH_PARTNERS_SUCCESS = "logistics/FETCH_PARTNERS_SUCCESS",
  FETCH_PARTNERS_FAILED = "logistics/FETCH_PARTNERS_FAILED",
  FETCH_PARTNER_START = "logistics/FETCH_PARTNER_START",
  FETCH_PARTNER_SUCCESS = "logistics/FETCH_PARTNER_SUCCESS",
  FETCH_PARTNER_FAILED = "logistics/FETCH_PARTNER_FAILED",
  ADD_PARTNER_START = "logistics/ADD_PARTNER_START",
  ADD_PARTNER_SUCCESS = "logistics/ADD_PARTNER_SUCCESS",
  ADD_PARTNER_FAILED = "logistics/ADD_PARTNER_FAILED",
  UPDATE_PARTNER_START = "logistics/UPDATE_PARTNER_START",
  UPDATE_PARTNER_SUCCESS = "logistics/UPDATE_PARTNER_SUCCESS",
  UPDATE_PARTNER_FAILED = "logistics/UPDATE_PARTNER_FAILED",
  DELETE_PARTNER_START = "logistics/DELETE_PARTNER_START",
  DELETE_PARTNER_SUCCESS = "logistics/DELETE_PARTNER_SUCCESS",
  DELETE_PARTNER_FAILED = "logistics/DELETE_PARTNER_FAILED",
}
