import { classNames } from "utils/helper/helper";
import { sidebarLinks } from "utils/helper/links";
import SidebarItem from "./sidebar-item.component";
import logoIcon from "assets/logo-icon.svg";
import MenuSearch from "./menu-search.component";
import { useSelector } from "react-redux";
import { selectSidebarSm } from "store/data/data.selector";
import LogoSvg from "assets/svgs/logo.svg";
import { Link } from "react-router-dom";

function Sidebar() {
  const isSmall = useSelector(selectSidebarSm);
  return (
    <div
      className={classNames(
        "app-menu border-r dark:border-dark-border",
        "transition-all duration-300 ease-in-out",
        "absolute left-0 top-0 z-[40] flex h-screen flex-col overflow-y-hidden",
        "bg-white lg:static lg:translate-x-0 -translate-x-full",
        "dark:bg-primary-950 group py-6",
        isSmall ? "w-16" : "w-72.5",
        isSmall && "sidebar-size-sm"
      )}
    >
      <div
        className={classNames(
          "flex items-center px-5 py-6 text-center h-header",
          "transition-all duration-300 ease-in-out"
        )}
      >
        <Link to={"/"} className="flex items-center gap-2">
          <span
            className={classNames(
              "transition-all duration-300 ease-in-out",
              isSmall ? "block" : "hidden"
            )}
          >
            <img src={logoIcon} alt="" className="h-6 w-6 mx-auto" />
          </span>
          <span
            className={classNames(
              "transition-all duration-300 ease-in-out",
              isSmall ? "hidden" : "block"
            )}
          >
            <LogoSvg className="h-6 w-auto mx-auto dark:text-white text-primary" />
          </span>
        </Link>
        <button
          type="button"
          className="hidden p-0 float-end"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>
      <div
        className={classNames(
          "px-3 mb-6",
          "transition-all duration-300 ease-in-out",
          isSmall ? "flex justify-center" : ""
        )}
      >
        <MenuSearch />
      </div>
      <div
        id="scrollbar"
        className="mx-auto hover:scrollbar-show overflow-y-auto h-full max-h-full"
      >
        <div
          className={classNames(
            "simplebar-wrapper",
            "transition-all duration-300 ease-in-out",
            isSmall ? "w-16" : "w-64"
          )}
        >
          <ul className="menu w-full gap-2 font-semibold">
            {sidebarLinks.map((link, i) => (
              <SidebarItem item={link} key={i} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
