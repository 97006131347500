import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Seller, SlackUser, User } from "utils/types/user";

export interface VendorFilters {
  search: string;
  status: "all" | "verified" | "unverified";
  dateRange: {
    startDate: string | null;
    endDate: string | null;
  };
}

interface UsersState {
  vendors: Seller[];
  customers: User[];
  all: User[];
  slackUsers: SlackUser[] | null;
  vendorFilters: VendorFilters;
}

const initialState: UsersState = {
  vendors: [],
  customers: [],
  all: [],
  slackUsers: null,
  vendorFilters: {
    search: "",
    status: "all",
    dateRange: {
      startDate: null,
      endDate: null,
    },
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    setVendors(state, action) {
      state.vendors = action.payload;
    },
    setUsers(state, action) {
      state.all = action.payload;
    },
    setSlackUsers: (state, action: PayloadAction<SlackUser[]>) => {
      state.slackUsers = action.payload;
    },
    setVendorSearch: (state, action: PayloadAction<string>) => {
      state.vendorFilters.search = action.payload;
    },
    setVendorStatus: (state, action: PayloadAction<"all" | "verified" | "unverified">) => {
      state.vendorFilters.status = action.payload;
    },
    setVendorDateRange: (state, action: PayloadAction<{ startDate: string | null; endDate: string | null }>) => {
      state.vendorFilters.dateRange = action.payload;
    },
    clearVendorFilters: (state) => {
      state.vendorFilters = initialState.vendorFilters;
    },
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice;
