import { takeLatest, put, all, call } from "typed-redux-saga";
import { SETTINGS_ACTION_TYPES } from "./settings.types";
import {
  fetchFaqsSuccess,
  fetchFaqsFailed,
  addFaqSuccess,
  addFaqFailed,
  updateFaqSuccess,
  updateFaqFailed,
  deleteFaqSuccess,
  deleteFaqFailed,
  fetchHelpArticlesSuccess,
  fetchHelpArticlesFailed,
  fetchHelpSectionsSuccess,
  fetchHelpSectionsFailed,
} from "./settings.actions";
import { apiRequest } from "lib/api/apiClient";
import { FAQ } from "utils/types/settings";
import { settingsActions } from "./settings.slice";

// FAQ Sagas
export function* fetchFaqs() {
  try {
    const faqs: FAQ[] = yield* call(apiRequest, "/api/v1/admin/faq");
    console.log("faqs: ", faqs);

    yield* put(settingsActions.setFaqs(faqs));
  } catch (error) {
    yield* put(fetchFaqsFailed(error as Error));
  }
}

export function* addFaq({ payload }: { payload: Omit<FAQ, "id"> }) {
  try {
    const faq: FAQ = yield* call(apiRequest, "/api/v1/admin/faq", {
      method: "POST",
      body: payload,
    });
    yield* put(addFaqSuccess(faq));
  } catch (error) {
    yield* put(addFaqFailed(error as Error));
  }
}

export function* updateFaq({ payload }: { payload: FAQ }) {
  try {
    const faq: FAQ = yield* call(
      apiRequest,
      `/api/v1/admin/faq/${payload.id}`,
      {
        method: "PUT",
        body: payload,
      }
    );
    yield* put(updateFaqSuccess(faq));
  } catch (error) {
    yield* put(updateFaqFailed(error as Error));
  }
}

export function* deleteFaq({ payload: id }: { payload: number }) {
  try {
    yield* call(apiRequest, `/api/v1/admin/faq/${id}`, {
      method: "DELETE",
    });
    yield* put(deleteFaqSuccess(id));
  } catch (error) {
    yield* put(deleteFaqFailed(error as Error));
  }
}

// Help Articles Sagas
export function* fetchHelpArticles() {
  try {
    const articles = yield* call(apiRequest, "/api/v1/admin/help-article");
    yield* put(settingsActions.setHelpArticles(articles));
  } catch (error) {
    yield* put(fetchHelpArticlesFailed(error as Error));
  }
}

// Help Sections Sagas
export function* fetchHelpSections() {
  try {
    const sections = yield* call(apiRequest, "/api/v1/admin/help-section");
    yield* put(settingsActions.setHelpSections(sections));
  } catch (error) {
    yield* put(fetchHelpSectionsFailed(error as Error));
  }
}

// Watchers
export function* onFetchFaqs() {
  yield* takeLatest(SETTINGS_ACTION_TYPES.FETCH_FAQS_START, fetchFaqs);
}

export function* onFetchHelpArticles() {
  yield* takeLatest(
    SETTINGS_ACTION_TYPES.FETCH_HELP_ARTICLES_START,
    fetchHelpArticles
  );
}

export function* onFetchHelpSections() {
  yield* takeLatest(
    SETTINGS_ACTION_TYPES.FETCH_HELP_SECTIONS_START,
    fetchHelpSections
  );
}

export function* settingsSaga() {
  yield* all([
    call(onFetchFaqs),
    call(onFetchHelpArticles),
    call(onFetchHelpSections),
  ]);
}
