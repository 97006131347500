export enum ORDER_ACTION_TYPES {
  FETCH_ORDERS_START = "order/FETCH_ORDERS_START",
  FETCH_ORDERS_SUCCESS = "order/FETCH_ORDERS_SUCCESS",
  FETCH_ORDERS_FAILED = "order/FETCH_ORDERS_FAILED",
  SET_ORDERS = "order/SET_ORDERS",
  SET_SEARCH_VALUE = "order/SET_SEARCH_VALUE",
  SET_DATE_RANGE = "order/SET_DATE_RANGE",
  SET_TIME_FILTER = "order/SET_TIME_FILTER",
  SET_SORT_OPTION = "order/SET_SORT_OPTION",
  SET_SELECTED_KEYS = "order/SET_SELECTED_KEYS",
  SET_LIMIT = "order/SET_LIMIT",
}
