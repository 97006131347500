import { takeLatest, put, all, call } from "typed-redux-saga/macro";
import { ORDER_ACTION_TYPES } from "./order.types";
import { fetchOrdersFailed } from "./order.actions";
import { apiRequest } from "lib/api/apiClient";
import { orderActions } from "./order.slice";

export function* fetchOrders() {
  try {
    const orders = yield* call(apiRequest, "/api/v1/admin/order");
    yield* put(orderActions.setOrders(orders));
  } catch (error) {
    yield* put(fetchOrdersFailed(error as Error));
  }
}

export function* onFetchOrders() {
  yield* takeLatest(ORDER_ACTION_TYPES.FETCH_ORDERS_START, fetchOrders);
}

export function* orderSaga() {
  yield* all([call(onFetchOrders)]);
}
