import { selectCurrency } from "store/data/data.selector";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import CurrencySwitcher from "./currency";

function LangCurrencySelector({ className = "" }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const currency = useSelector(selectCurrency);
  return (
    <div className={className}>
      <Button variant="light" onPress={onOpen} className={className}>
        <span className="capitalize">Nigeria - {currency?.code || "NGN"}</span>
      </Button>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Language and Currency Selector
              </ModalHeader>
              <ModalBody className="py-6 space-y-4">
                {/* <LangDropdown variant="bordered" /> */}
                <CurrencySwitcher />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

export default LangCurrencySelector;
