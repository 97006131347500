import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FAQ,
  HelpArticle,
  HelpSection,
  Metadata,
  SettingsState,
} from "utils/types/settings";

const initialState: SettingsState = {
  faqs: null,
  help_articles: null,
  help_sections: null,
  intro_mode: false,
  coupon_newsletter: false,
  metadata: {
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
  },
  loading: false,
  error: null,
  live_mode: true,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setFaqs: (state, action: PayloadAction<FAQ[]>) => {
      state.faqs = action.payload;
    },
    addFaq: (state, action: PayloadAction<FAQ>) => {
      state.faqs = state.faqs
        ? [...state.faqs, action.payload]
        : [action.payload];
    },
    updateFaq: (state, action: PayloadAction<FAQ>) => {
      if (state.faqs) {
        state.faqs = state.faqs.map((faq) =>
          faq.id === action.payload.id ? action.payload : faq
        );
      }
    },
    deleteFaq: (state, action: PayloadAction<number>) => {
      if (state.faqs) {
        state.faqs = state.faqs.filter((faq) => faq.id !== action.payload);
      }
    },
    setHelpArticles: (state, action: PayloadAction<HelpArticle[]>) => {
      state.help_articles = action.payload;
    },
    setHelpSections: (state, action: PayloadAction<HelpSection[]>) => {
      state.help_sections = action.payload;
    },
    setIntroMode: (state, action: PayloadAction<boolean>) => {
      state.intro_mode = action.payload;
    },
    setCouponNewsletter: (state, action: PayloadAction<boolean>) => {
      state.coupon_newsletter = action.payload;
    },
    setMetadata: (state, action: PayloadAction<Metadata>) => {
      state.metadata = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setLiveMode: (state, action: PayloadAction<boolean>) => {
      state.live_mode = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;
export default settingsSlice;
