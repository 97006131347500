import Preloader from "components/preloader";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import router from "routes";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <Suspense fallback={<Preloader />}>
        <RouterProvider router={router} />
      </Suspense>

      <ToastContainer />
    </GoogleOAuthProvider>
  );
}

export default App;
