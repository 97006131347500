import {
  Action,
  ActionWithPayload,
  withMatcher,
  createAction,
} from "utils/reducer/reducer.utils";
import { USERS_ACTION_TYPES } from "./users.types";

export type FetchCustomers = Action<USERS_ACTION_TYPES.FETCH_CUSTOMERS>;
export type FetchVendor = Action<USERS_ACTION_TYPES.FETCH_VENDORS>;
export type FetchSlackUsers = Action<USERS_ACTION_TYPES.FETCH_SLACK_USERS>;
export const fetchCustomers = withMatcher(
  (): FetchCustomers => createAction(USERS_ACTION_TYPES.FETCH_CUSTOMERS)
);

export const fetchVendors = withMatcher(
  (): FetchVendor => createAction(USERS_ACTION_TYPES.FETCH_VENDORS)
);

export const fetchSlackUsers = withMatcher(
  (): FetchSlackUsers => createAction(USERS_ACTION_TYPES.FETCH_SLACK_USERS)
);
