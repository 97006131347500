import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { logger } from "../logger";
import { alertMessage } from "components/toolkit/initial-state.component";
import { removeFromCookieProtected } from "utils/helper/helper";
import { AUTH_TOKEN } from "utils/helper/states";
import { authLinks } from "utils/helper/links";

interface ApiOptions {
  method?: string;
  body?: any;
  headers?: HeadersInit;
}

export const apiRequest = async (
  link: string,
  options: ApiOptions = {},
  isURL = false
) => {
  try {
    const envProps = await getEnvProps();
    const hostURL = envProps.base_url;

    const uri = isURL ? link : `${hostURL}${link}`;
    const isFormData = options.body instanceof FormData;
    const defaultHeaders = await getHeaders(undefined, isFormData);

    const fetchOptions: RequestInit = {
      method: options.method || "GET",
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
      body: isFormData
        ? options.body
        : options.body
        ? JSON.stringify(options.body)
        : undefined,
    };

    const res = await fetch(uri, fetchOptions);
    const data = await res.json();

    if (data.data != null) {
      return data.data;
    }
    if (data.message != null) {
      alertMessage("warn", data.message);
      removeFromCookieProtected(AUTH_TOKEN);
      if (
        data.message === "Unauthenticated." &&
        window.location.pathname !== authLinks.login
      ) {
        window.location.href = authLinks.login;
      }
    }
  } catch (error) {
    logger.error(error as Error); // Log the error using logger.error()
  }
};
