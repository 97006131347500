import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderItem, OrderState } from "utils/types/order";

const initialState: OrderState = {
  orders: null,
  filteredOrders: null,
  loading: false,
  error: null,
  searchValue: "",
  timeFilter: "all_time",
  sortOption: "date_desc",
  selectedKeys: [],
  limit: 12,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<OrderItem[]>) => {
      state.orders = action.payload;
      state.filteredOrders = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setTimeFilter: (state, action: PayloadAction<string>) => {
      state.timeFilter = action.payload;
    },
    setSortOption: (state, action: PayloadAction<string>) => {
      state.sortOption = action.payload;
    },
    setSelectedKeys: (state, action: PayloadAction<number[]>) => {
      state.selectedKeys = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
  },
});

export const orderActions = orderSlice.actions;
export default orderSlice;
